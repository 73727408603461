﻿import { IPartialTheme, loadTheme } from "@fluentui/react";
import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from "react-error-boundary";
import { CheckSSODirect, GetAntiForgeryToken, LoginWithAuthToken, Logout, ValidateRenewToken, deleteCookieAuth, getAuthObject, getCookieAuth, getPortalConfigLogin, msalConfig } from "../apiFunctions/authenticateFunctions";
import { LoginScreen } from './login';
import { createBrowserRouter, json, Navigate, RouterProvider, useMatch, useParams } from "react-router-dom";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
//import { setEndpoints } from "documentdrafter-components";
import { SignalRClient } from "../containers/signalRClient";
import { Resizer } from "../containers/resizer";
import { MainPage } from "./main/mainPage";
import useStore from "../apiFunctions/store/store";
import { AdminScreen } from "./admin/admin";
import { QuestionnaireScreen } from "./questionnaire/questionnaire";
import usePersistentStore from "../apiFunctions/store/persistentStore";
import { ErrorHandler } from "../containers/errorhandler";
import { CustomFluentProvider, ScreenSize, setEndpoints, getEndpoints, GetWorkspaces, getCustomTheme, initializeResources, GetPortalConfig } from "documentdrafter-components";
import { IConfigLogin } from "./ddentities";
import { LoginLinkScreen } from "./loginLink";
import { LogoutScreen } from "./logout";
import { LoginDocumentLinkScreen } from "./loginDocumentLink";
import { PreviewWindow } from "./questionnaire/previewWindow";
import { SharePointConnected } from "./sharepointconnected";
import { DataManagerPage } from "./datamanager/datamanagerPage";
import { FluentProvider, Spinner, themeToTokensObject, tokens, webDarkTheme, webLightTheme } from "@fluentui/react-components";
import { LinkingBitFolderNav } from "./linkingBit/folderNavigator";
import { IUserProps } from "./entitiesAPI";
//import { QuestionnairePage } from "component-library-gmeadow/dist/components/questionnaire";
import { useNavigate } from 'react-router-dom';
import { DeepLinkHandler } from "./deepLinkHandler";
import { nukeSession } from "../apiFunctions/questionnairefunctions";
import { EntraConnected } from "./entra-connected";
import { PhraseLibrarySelector } from "./phraseLibrary/phraseSelector";
import { datePickerClassNames } from "@fluentui/react-datepicker-compat";
import { OpenIdLogin } from "./loginOpenId";
import { useLocation } from 'react-router-dom';
import { PublicClientApplication } from "@azure/msal-browser";
import { GetPortalLinks } from "../apiFunctions/baseFunctions/adminFunctions";
import { PublicSiteHandler } from "./publicSiteHandler";
import { PublicSiteRedirect } from "./publicSiteRedirect";


let initializedEndpoints: boolean = false;

export const App: React.FC = () => {
    const timeoutRef = React.useRef(null);


    const [config, setConfig] = useState<IConfigLogin>(null);
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const [isLoading, setIsLoading] = useState(true);
    const [doNavigate, SetDoNavigate] = useState(null);

    const portalConfig = useStore().portalConfig;
    const isUserAuthenticated = useStore(state => state.isUserAuthenticated);

    if (portalConfig !== undefined) {
        document.title = portalConfig.portaltitle;
        const favIcon = document.getElementById("favicon") as HTMLLinkElement;
        favIcon.href = portalConfig.favicon;
    }

    const theme = usePersistentStore().currentTheme;
    const isDark = usePersistentStore().darkMode;
    const endpoints = useStore().endpoints;
    const userObj = useStore().userObj;

    const isPublicUser = userObj?.isPublic ?? false;

    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const screenSize = useStore().screenSize;
    const currentpath = window.location.pathname + window.location.search;
    const [initReady, setInitReady] = useState(false);
    const [initDone, setInitDone] = useState<boolean>(false);
    const [timeOutClient, setTimeOutClient] = useState(null);
    const timeOutSender = React.useRef(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
    const [SSODirect, SetSSOdirect] = useState<string>("");
    const [loginType, setLoginType] = useState(getCookie("GMo365") || "0") //read old setting or default

    const windowId = sessionStorage.getItem("windowId")||Math.random().toString(36).substring(2);


    const [isDeepLink, setIsDeepLink] = React.useState<boolean>(false);
    const [isEmbedded, setIsEmbedded] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (window.self !== window.top) {
            console.log("isEmbedded triggered");
            setIsEmbedded(true);
        } else {
            setIsEmbedded(false);
        }
        console.log("location", window.location);
        if (window.location.pathname.indexOf("/document/") > -1) {
            setIsDeepLink(true);
        }
        if ((window.location.pathname.indexOf("/user") > -1 && window.location.search.indexOf("nextUrl=/document/"))) {
            setIsDeepLink(true);
        }
        if ((window.location.pathname.indexOf("/link") > -1 && window.location.search.indexOf("nextUrl=/document/"))) {
            setIsDeepLink(true);
        }
        if ((window.location.pathname.indexOf("/loginlink") > -1 && window.location.search.indexOf("nextUrl=/document/"))) {
            setIsDeepLink(true);
        }

    }, []);

    useEffect(() => {
        if (userObj && userObj.requestToken && userObj.userid && initDone && isUserAuthenticated) {
            try {
                GetPortalLinks(userObj).then((linksResult) => {
                    useStore.setState({ portalLinks: linksResult });
                });
                GetPortalConfig(userObj).then((configResult) => {
                    useStore.setState({ portalConfig: configResult })
                    sessionStorage.setItem("config", JSON.stringify(configResult));
                });
            } catch (e) {

            }

        }
        sessionStorage.setItem("windowId", windowId);
    }, [userObj, initDone]);

    //useEffect(() => {

    //    var portalUrl = window.location.hostname.indexOf('localhost') > -1 ? 'v2020.dev.documentdrafter.com' : window.location.hostname;
    //    getEndpoints(portalUrl).then((endpointResult) => {
    //        useStore.setState({ endpoints: endpointResult });
    //        setEndpoints(endpointResult);
    //        setTimeout(() => {
    //            initializeResources(portalUrl).then(() => {

    //            });

    //        }, 500);
    //    });

    //}, []);

    if (!initializedEndpoints) {
        initializedEndpoints = true;

        //setEndpoints.authenticationAPI = "https://localhost:44364/";
        // endpoints.questionnaireAPI = "https://localhost:44329/";
        //endpoints.baseAPI = "https://localhost:44337/"; 
        if (window.location.hostname === "localhost") {
            // Use environment variable for endpoint
            if (import.meta.env.VITE_APP_QUESTIONNAIRE_API) {
                endpoints.questionnaireAPI = import.meta.env.VITE_APP_QUESTIONNAIRE_API;
            }
            if (import.meta.env.VITE_APP_BASE_API) {
                endpoints.baseAPI = import.meta.env.VITE_APP_BASE_API;
            }
            if (import.meta.env.VITE_APP_AUTH_API) {
                endpoints.authenticationAPI = import.meta.env.VITE_APP_AUTH_API;
            }

        }
        setEndpoints(endpoints);
    }


    const resetTimeout = () => {
        if (timeoutRef.current) {
            const channel = new BroadcastChannel('timeOut');
            channel.postMessage("clear:" + timeOutSender.current);
            clearTimeout(timeoutRef.current);
        }

        if (timeOutClient === null) return;

        // Set the timeout for 120 minutes
        timeoutRef.current = setTimeout(() => {
            const channel = new BroadcastChannel('timeOut');
            channel.postMessage("timeout:" + timeOutSender.current);
            // Call your logout function here
            document.location = "/timeout";

        }, timeOutClient * 60 * 1000);
    };
    /*let timeoutStart = null;*/

    useEffect(() => {
        //user-obj in session storage might have changed.
        if (isUserAuthenticated) {
            getAuthObject().then((userObjResult) => {

                useStore.setState({ userObj: userObjResult });

            });
        }



    }, []);

    //const logRemainingTime = () => {
    //    if (timeoutStart) {
    //        const elapsed = Date.now() - timeoutStart;
    //        const remaining = portalConfig.Security.timeOutScreen * 60 * 1000 - elapsed;
    //        console.log(`Remaining time: ${remaining / 1000} seconds`);
    //    }
    //};

    //// Call logRemainingTime every second to log the remaining time
    //setInterval(logRemainingTime, 1000);



    useEffect(() => {
        const handleVisibilityChange = async () => {
            const currentPath = window.location.pathname;

            if (currentPath === '/logout' || currentPath === '/timeout' || currentPath ==='/logout-tab' ) {
                return;
            }

            if (document.visibilityState === 'visible' ) {
                if (isUserAuthenticated) {
                    var userState = useStore.getState().userObj;
                    try {
                        var newToken = await ValidateRenewToken(userState.requestToken, userState.userid);
                        if (newToken != userState.requestToken) {
                            userState.requestToken = newToken;
                            useStore.setState({ userObj: userState });
                            var sessionUserObj = JSON.parse(sessionStorage.getItem("user"));
                            sessionUserObj.requestToken = newToken;
                            sessionStorage.setItem("user", JSON.stringify(sessionUserObj));

                            console.info('Token renew compled succesfully')
                        }

                    } catch (e) {
                        //user not authenticated any more - redirect login
                        console.info('Token renew completed unsuccesfully')
                        useStore.setState({
                            isUserAuthenticated: false
                        });
                        useStore.setState({ userObj: null });
                        document.location = "/user";
                    }
                }


            }
        };

        window.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            window.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);


    useEffect(() => {
        const channel = new BroadcastChannel('logout_channel');
        const channelTimeOut = new BroadcastChannel("timeOut");

        channel.addEventListener('message', event => {
            if (event.data.action === 'logout' && event.data.senderId !== windowId) {
                // Perform your logout logic here, like clearing session storage or cookies
                console.info('Logging out in response to a broadcasted logout event:' + event);
                document.location = "/logout-tab";
            }
        });


        channelTimeOut.addEventListener('message', event => {
            var _event = event.data as string;

            if (_event !== 'clear:' + timeOutSender.current) {
                // Perform your logout logic here, like clearing session storage or cookies
                /*  console.log('broadcasted clear TimeOut event:' + event.data);*/
                clearTimeout(timeoutRef.current);
            }
            if (_event.startsWith("timeout") && _event !== 'timeout:' + timeOutSender.current) {
                // Perform your logout logic here, like clearing session storage or cookies
                //console.log('broadcasted clear TimeOut event:' + event.data);
                document.location = "/timeout";
            }
        });

    }, []);



    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    //preloader
    useEffect(() => {

        sessionStorage.setItem("context", "Portal");

        var portalUrl = window.location.hostname.indexOf('localhost') > -1 ? 'v2020.dev.documentdrafter.com' : window.location.hostname;
        getEndpoints(portalUrl).then((endpointResult) => {
            if (window.location.hostname === "localhost") {
                if (import.meta.env.VITE_APP_QUESTIONNAIRE_API) {
                    endpointResult.questionnaireAPI = import.meta.env.VITE_APP_QUESTIONNAIRE_API;
                }
                if (import.meta.env.VITE_APP_BASE_API) {
                    endpointResult.baseAPI = import.meta.env.VITE_APP_BASE_API;
                }
                if (import.meta.env.VITE_APP_AUTH_API) {
                    endpointResult.authenticationAPI = import.meta.env.VITE_APP_AUTH_API;
                }

            }
            useStore.setState({ endpoints: endpointResult });
            setEndpoints(endpointResult);
            setTimeout(() => {
                initializeResources(portalUrl).then(() => {

                    if (initReady) return;

                    if (isUserAuthenticated) {
                        setInitReady(true);
                        return;
                    }

                    const checkCookie = (cookieName, callback) => {
                        const intervalId = setInterval(() => {
                            //console.log('cookie check')
                            const cookieValue = getCookie(cookieName);
                            if (cookieValue !== "") {
                                clearInterval(intervalId);
                                callback();
                            }
                        }, 200);
                    };



                    const fetchAntiForgeryToken = async () => {
                        await GetAntiForgeryToken();

                        checkCookie("RequestVerificationToken", () => {
                            setInitReady(true);
                        });

                    };


                    fetchAntiForgeryToken();

                });

            }, 500);
        });

    }, []);



    //init and get auth token
    useEffect(() => {

        var init = async () => {

            var portalConfig = await getPortalConfigLogin();
            var _ssoDirect = await CheckSSODirect();
            SetSSOdirect(_ssoDirect);
            if (_ssoDirect != "") {
                if (_ssoDirect.toLowerCase() == "true") {
                    setLoginType("1");
                } else if (_ssoDirect.length > 1) {
                    setLoginType("2");
                }
            }

            setConfig(portalConfig);
            setTimeOutClient(portalConfig.config.Security.timeOutScreen);

            useStore.setState({ portalConfig: portalConfig.config });
            sessionStorage.setItem("config", JSON.stringify(portalConfig.config));
            if (window.location.search.indexOf('addinload') > -1) {
                //force addin login
                sessionStorage.setItem("addinload", "1");
            }

            if (window.location.search.indexOf("directADD") > -1) {
                localStorage.setItem("directADD", "true");
            }

            var cookie = getCookieAuth("dd.sec.session");

            if (!isUserAuthenticated && cookie != undefined) {
                var loginObj = await LoginWithAuthToken(document.location.hostname, cookie);
                if (loginObj.status === "verified") {

                    var csrfToken = getCookie("RequestVerificationToken");
                    try {
                        var data = await fetch('/api/session', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'RequestVerificationToken': csrfToken
                            },
                            body: JSON.stringify(loginObj.session)
                        });
                    } catch (e) {
                        console.error(e);
                        setIsLoading(false);
                        return;
                    }

                    sessionStorage.setItem("user", JSON.stringify(loginObj.session));
                    const newUserObj: IUserProps = {
                        requestToken: "",
                        userid: "",
                        useremail: loginObj.session.portalUser.Description.email,
                        username: loginObj.session.portalUser.Description.name,
                        casenumberRequired: false,
                        customerName: "",
                        rightslist: loginObj.session.rightslist,
                        permissions: loginObj.session.permissions,
                        customerid: loginObj.session.customerid,
                        guestUser: loginObj.session.portalUser.Groups.indexOf("55555555") > -1,
                        isPublic: loginObj.session.portalUser.IsPublicUser,
                        mainUrl: loginObj.session.MainUrl
                    };
                    newUserObj.requestToken = loginObj.session.requestToken;
                    newUserObj.userid = loginObj.session.portalUser.id;
                    newUserObj.casenumberRequired = loginObj.session.casenumberrequired;
                    newUserObj.customerName = loginObj.session.customername;

                    useStore.setState({ isUserAuthenticated: true, portalConfig: loginObj.PortalConfig });
                    sessionStorage.setItem("config", JSON.stringify(loginObj.PortalConfig));
                    useStore.setState({ userObj: newUserObj });
                    portalConfig = loginObj.PortalConfig;

                    var portalUrl = window.location.hostname.indexOf('localhost') > -1 ? 'v2020.dev.documentdrafter.com' : window.location.hostname;
                    var endpointResult = await getEndpoints(portalUrl);
                    if (window.location.hostname === "localhost") {
                        // Use environment variable for endpoint
                        if (import.meta.env.VITE_APP_QUESTIONNAIRE_API)
                            endpointResult.questionnaireAPI = import.meta.env.VITE_APP_QUESTIONNAIRE_API;

                        if (import.meta.env.VITE_APP_BASE_API)
                            endpointResult.baseAPI = import.meta.env.VITE_APP_BASE_API;

                        if (import.meta.env.VITE_APP_AUTH_API)
                            endpointResult.authenticationAPI = import.meta.env.VITE_APP_AUTH_API;


                    }

                    //endpointResult.questionnaireAPI = "https://localhost:44329/";
                    useStore.setState({ endpoints: endpointResult });
                    setEndpoints(endpointResult);
                    var workspacesResult = await GetWorkspaces(newUserObj);
                    useStore.setState({ availableWorkspaces: workspacesResult });
                    const search = window.location.search;

                    if (search.indexOf('addinload') > -1 || sessionStorage.getItem("addinload")) {

                        document.location = "/addinstatus?userid=" + newUserObj.useremail + "&customerid=" + newUserObj.customerid;
                    }


                    setIsLoading(false);
                    setInitDone(true);
                }
                else { setInitDone(true); setIsLoading(false); }

            }
            else { setInitDone(true); setIsLoading(false); }

        }

        if (initReady) { init(); }




    }, [initReady])



    useEffect(() => {
        if (!isLoading && isUserAuthenticated) {

            window.addEventListener('keypress', resetTimeout);
            window.addEventListener('click', resetTimeout);

            // Clean up event listeners on unmount
            return () => {
                window.removeEventListener('keypress', resetTimeout);
                window.removeEventListener('click', resetTimeout);
            };
        }
    }, [isLoading, isUserAuthenticated]);


    async function logMeOut() {

        //if (sessionStorage.getItem("msal.account.keys") != null) {
        //    //MSAL log me out
        //    const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig);
        //    msalInstance.initialize().then(async () => {
        //        msalInstance.logout()

        //    });

        //}
        await Logout(userObj);
        useStore.setState({ isUserAuthenticated: false, userObj:undefined });
        usePersistentStore.setState({ userObj: undefined });
        sessionStorage.removeItem("user");

        
    }

    function OpenIdLoginWrapper({ config }) {
        const params = useParams();


        return <OpenIdLogin ConfigLogin={config} Provider={params.provider} />;
    }




    //function QuestionnaireWrapper() {
    //    const navigate = useNavigate();
    //    const location = useLocation();
    //    const params = new URLSearchParams(location.search);
    //    const fileId = params.get('fileId');
    //    const match = useMatch("/document/questionnaire");

    //    console.log("inside questionnaireWrapper");

    //    if (fileId && match) {
    //        console.log("inside if fileId: ", fileId);
    //        console.log("inside if match: ", match);
    //        return <Navigate
    //            replace
    //            to={`/document/questionnaire/${fileId}`} />
    //    }
    //    else
    //        return <QuestionnaireScreen />;

    //}


    function LoginWrapper() {
        var nextUrl = "";

        if (document.location.pathname.indexOf("/user") > -1) {
            nextUrl = document.location.search;
            return <Navigate
                replace
                to={`/user${nextUrl}`} />

        }
        else {
            nextUrl = document.location.pathname + document.location.search;

            return <Navigate
                replace
                to={`/user?nextUrl=${encodeURIComponent(nextUrl)}`} />
        }




    }



    const router = createBrowserRouter([
        {
            path: "/user/oidc/:provider",
            element: (
                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >
                    <OpenIdLoginWrapper config={config} />
                </ErrorBoundary>
            ),
        },
        {
            path: "/user",
            element: (
                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >
                    <LoginScreen ConfigLogin={config} Token={token} LoginType={loginType} SSODirect={SSODirect} EmailToken={""} />
                </ErrorBoundary>
            ),
        },

        {
            path: "/link",
            element: (
                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >



                    <LoginDocumentLinkScreen ConfigLogin={config} Token={token} />
                </ErrorBoundary>
            ),
        },
        {
            path: "/loginlink",
            element: (
                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >
                    <LoginScreen ConfigLogin={config} Token={null} LoginType={loginType} SSODirect={SSODirect} EmailToken={token} />
                   
                </ErrorBoundary>
            ),
        },

        {
            path: "/logout",
            element: (
                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >
                    <LogoutScreen SendMessage={true} ConfigLogin={config} timeOut={false} logMeOut={logMeOut} windowId={windowId} />
                </ErrorBoundary>
            ),
        },
        {
            path: "/timeout",
            element: (
                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >
                    <LogoutScreen SendMessage={false} ConfigLogin={config} timeOut={true} logMeOut={logMeOut} windowId={windowId} />
                </ErrorBoundary>
            ),
        },
        {
            path: "/logout-tab",
            element: (
                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >
                    <LogoutScreen SendMessage={false} ConfigLogin={config} timeOut={false} logMeOut={logMeOut} windowId={windowId} />
                </ErrorBoundary>
            ),
        },
        {
            path: "/",
            element: isUserAuthenticated ? (
                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >
                    <MainPage workspacePreview="" />
                    {/*{isPublicUser && <PublicSiteRedirect ConfigLogin={config} />}*/}
                </ErrorBoundary>
            ) : <Navigate replace to={"/user?nextUrl=/"} />
        },


        {
            path: "/dl/*",

            element: (

                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >
                    <DeepLinkHandler ConfigLogin={config} />
                </ErrorBoundary>
            ),
        },

        {
            path: "/publicSite/*",

            element: (

                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >
                    <PublicSiteHandler ConfigLogin={config} />
                </ErrorBoundary>
            ),
        },
        {
            path: "/document/*",

            element: (
                isUserAuthenticated ? (
                    <ErrorBoundary
                        FallbackComponent={ErrorHandler}
                        onError={(e: Error, i: React.ErrorInfo) => {
                            //eslint-disable-next-line
                            console.log("error caught by ErrorBoundary: ", e);
                            console.log("error info: ", i);
                        }}
                    >
                        <QuestionnaireScreen isDeeplinkedEmbedded={isDeepLink && isEmbedded} />
                    </ErrorBoundary>) : <LoginWrapper />
            ),
        },



        {
            path: "/drafter/linkingbit/selectfolder",
            element: (

                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >
                    <FluentProvider theme={!isDark ? webLightTheme : webDarkTheme}>
                        <LinkingBitFolderNav action="selectFolder" />
                    </FluentProvider>
                </ErrorBoundary>
            ),
        },


        {
            path: "/drafter/phrase-lib/select",
            element: (

                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >
                    <FluentProvider theme={!isDark ? webLightTheme : webDarkTheme}>
                        <PhraseLibrarySelector action="select" />
                    </FluentProvider>
                </ErrorBoundary>
            ),
        },


        {
            path: "/drafter/linkingbit/selectFile",
            element: (
                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >
                    <FluentProvider theme={!isDark ? webLightTheme : webDarkTheme}>
                        <LinkingBitFolderNav action="selectFile" />
                    </FluentProvider>
                </ErrorBoundary>
            ),
        },
        {
            path: "/drafter/linkingbit/manager",
            element: (
                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >
                    <FluentProvider theme={!isDark ? webLightTheme : webDarkTheme}>
                        <LinkingBitFolderNav action="manager" />
                    </FluentProvider>
                </ErrorBoundary>
            ),
        },

        {
            path: "/datamanager",
            element: (isUserAuthenticated ? (
                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >
                    <FluentProvider theme={!isDark ? webLightTheme : webDarkTheme}>
                        <DataManagerPage />
                    </FluentProvider>
                </ErrorBoundary>) : <Navigate
                replace
                to={`/user?nextUrl=${encodeURIComponent(document.location.pathname + document.location.search)}`} />
            ),
        },

        //{
        //    path: "/flowmanager",
        //    element: (isUserAuthenticated ? (
        //        <ErrorBoundary
        //            FallbackComponent={ErrorHandler}
        //            onError={(e: Error, i: React.ErrorInfo) => {
        //                //eslint-disable-next-line
        //                console.log("error caught by ErrorBoundary: ", e);
        //                console.log("error info: ", i);
        //            }}
        //        >
        //            <FluentProvider theme={!isDark ? webLightTheme : webDarkTheme}>
        //                <FlowManagerPage />
        //            </FluentProvider>
        //        </ErrorBoundary>) : <Navigate
        //        replace
        //        to={`/user?nextUrl=${encodeURIComponent(document.location.pathname + document.location.search)}`} />
        //    ),
        //},

        {
            path: "/sharepointconnected",
            element: (isUserAuthenticated ? (
                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={(e: Error, i: React.ErrorInfo) => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary: ", e);
                        console.log("error info: ", i);
                    }}
                >
                    <SharePointConnected ConfigLogin={config} />
                </ErrorBoundary>) : <Navigate replace to={"/user"} />
            ),
        },

        {
            path: "/entra-connected",
            element: (

                <EntraConnected ConfigLogin={config} />

            ),
        },
        //{
        //    path: "/admin",
        //    element: (
        //        <ErrorBoundary
        //            FallbackComponent={ErrorHandler}
        //            onError={() => {
        //                //eslint-disable-next-line
        //                console.log("error caught by ErrorBoundary");
        //            }}
        //        >
        //            {isUserAuthenticated && <AdminScreen></AdminScreen>}
        //        </ErrorBoundary>
        //    ),
        //},

        {
            path: "/preview",
            element: (isUserAuthenticated ? (
                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={() => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary");
                    }}
                >
                    <PreviewWindow />
                </ErrorBoundary>) : <Navigate replace to={"/user"} />

            ),
        },
        {
            path: "/previewWorkspace/*",
            element: isUserAuthenticated ? (
                <ErrorBoundary
                    FallbackComponent={ErrorHandler}
                    onError={() => {
                        //eslint-disable-next-line
                        console.log("error caught by ErrorBoundary");
                    }}
                >

                    <MainPage workspacePreview={document.location.href.split("/previewWorkspace/")[1]} />
                </ErrorBoundary>
            ) : <Navigate replace to={"/user"} />
        },
    ]);

    const usedConfig = portalConfig ?? config?.config;

    if (isLoading) {
        return (<CustomFluentProvider isDark={isDark} colorConfiguration={undefined}>
            <div
                style={{
                    height: "100dvh",
                    width: "100vw",
                    background:
                        screenSize !== ScreenSize.mobile
                            ? `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`
                            : tokens.colorNeutralBackground1,
                }}
            >
                <div
                    style={{ padding: tokens.spacingVerticalXL }}
                >  {/* <Spinner size="extra-tiny" label="Loading page" />.*/}</div>
            </div>


        </CustomFluentProvider>); // Or some loading spinner
    } else {
        return (
            <Resizer>
                <CustomFluentProvider isDark={isDark} colorConfiguration={usedConfig?.colorConfiguration}>
                    <RouterProvider router={router} />
                </CustomFluentProvider>
            </Resizer>
        );
    }

}




initializeFileTypeIcons();


createRoot(document.getElementById("root")!).render(
    <React.StrictMode>

        <App></App>

    </React.StrictMode>
);




