import React from "react";
import { CustomFluentProvider, QuestionnairePage } from "documentdrafter-components";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import { IDirtyAnswer, IQuestionnaireObj } from "documentdrafter-components";
import { webDarkTheme, webLightTheme } from "@fluentui/react-components";
import { IUserProps } from "../entitiesAPI";
import { LinksPageTypes } from "../ddentities";

let questionnaireObj: IQuestionnaireObj | undefined;
let dirtyAnswers: IDirtyAnswer[] = [];

export const QuestionnaireScreen: React.FC<{ isDeeplinkedEmbedded? : boolean}> = (props) => {
    const userObj = useStore().userObj;
    const portalConfig = useStore().portalConfig;
    const isDark = usePersistentStore().darkMode;
    const theme = isDark ? webDarkTheme : webLightTheme;
    const navigate = useNavigate();
    const routerLocation = useLocation();
    const endpoints = useStore().endpoints;
    const isUserAuthenticated = useStore().isUserAuthenticated;
    const screenSize = useStore().screenSize;
    const portalLinks = useStore().portalLinks;

    const [pathVerified, setPathVerified] = React.useState<boolean>(false);

    React.useEffect(() => {

        console.info("opening questionnaire")
        if (!isUserAuthenticated) {
            const currentpath = window.location.pathname + window.location.search;

            if (!userObj) navigate(`/user?nextUrl=${currentpath}`, { replace: true });
            else {
                navigate(`/user?nextUrl=${currentpath}`, { replace: true });
            }
        }

        document.addEventListener('visibilitychange', beforeUnloadFunction);

        //const match = useMatch("/document/questionnaire");

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const fileId = params.get("fileId");
        const docload = params.get("docload");
        const localClauseId = params.get("clauseID");
        const cdocid = params.get("cdocid");

        //const questionnairePath = props.context === "Teams" ? "/tab/" : "/";

        if (fileId) {

            let newurl = "";

            if (cdocid && localClauseId) {
                newurl = `/document/clause/${fileId}/${cdocid}/${localClauseId}`;
            } else if (docload === "1") {
                newurl = `/document/${fileId}`;
            } else {
                newurl = `/document/questionnaire/${fileId}`;
            }
            //history.replaceState(null, "", newurl);
            navigate(newurl, { replace: true });
            setTimeout(() => { setPathVerified(true); }, 300);
        }
        else
            setPathVerified(true);

        return () => {
            questionnaireObj = undefined;
            dirtyAnswers = [];
            document.removeEventListener('visibilitychange', beforeUnloadFunction);
        };
    }, []);

    //React.useEffect(() => {
    //    debugger;
    //    if (!isUserAuthenticated) {
    //        const currentpath = window.location.pathname + window.location.search;

    //        if (!userObj) navigate(`/auth?nextUrl=${currentpath}`, { replace: true });
    //        else {
    //            navigate(`/auth?nextUrl=${currentpath}`, { replace: true });
    //        }
    //    }
    //}, [isUserAuthenticated]);

    function postBeacon() {
        let domainUrl = document.domain;
        if (document.location.port != undefined) {
            domainUrl += ':' + document.location.port;
        }

        let Endpoint = `https://` + domainUrl + `/api/Session/PostBeacon`;
        if (questionnaireObj) {
            const blob = new Blob([JSON.stringify({ pushAnswers: dirtyAnswers, caseNumber: questionnaireObj?.caseNumber, guid: questionnaireObj.guid })], { type: 'application/json; charset=UTF-8' });
            navigator.sendBeacon(Endpoint, blob);
        }
    }
    function beforeUnloadFunction(/*event: BeforeUnloadEvent*/) {

        if (document.visibilityState === 'hidden') {
            postBeacon();
        }

    }

    if (!pathVerified)
        return <div></div>

    return (
        <CustomFluentProvider isDark={isDark} colorConfiguration={portalConfig?.colorConfiguration}>
            <QuestionnairePage
                userObj={userObj}
                portalConfig={portalConfig}
                theme={theme}
                isDark={isDark}
                setIsDark={(dark) => {
                    usePersistentStore.setState({ darkMode: dark });
                }}
                navigate={navigate}
                routerLocation={routerLocation.pathname}
                endpoints={endpoints}
                setQuestionnaireObj={(obj: IQuestionnaireObj) => {
                    questionnaireObj = obj;
                }}
                setDirtyAnswers={(da: IDirtyAnswer[]) => {
                    dirtyAnswers = da;
                }}
                beforeHeaderNavigation={postBeacon}
                screenSize={screenSize}
                setUserObj={(newUserObj: IUserProps | undefined) => {

                    useStore.setState({ userObj: newUserObj });
                    if (!newUserObj)
                        useStore.setState({ isUserAuthenticated: false });

                }}
                portalLinks={portalLinks?.find((x) => x.page === LinksPageTypes.Questionnaire)}
                context={props.isDeeplinkedEmbedded ? "Portal-EmbeddedDeepLink" : "Portal"}
            />
        </CustomFluentProvider>
    );
};