﻿import { useState, useEffect, useLayoutEffect } from "react";
import {
    Theme,
    teamsLightTheme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    webLightTheme,
    webDarkTheme,
    themeToTokensObject,
    Button,
    DataGrid,
    tokens,
    Menu,
    MenuItem,
    MenuTrigger,
    MenuButton,
    MenuPopover,
    MenuList,
    makeStyles,
    TabList,
    Tab,
    shorthands,
    TableColumnDefinition,
    createTableColumn,
    TableCellLayout,
    DataGridHeader,
    DataGridRow,
    DataGridHeaderCell,
    DataGridBody,
    DataGridCell,
    TableHeaderCell,
    TableRowId,
    DataGridProps,
    OverlayDrawer,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerBody,
    Drawer,
    Field,
    Input,
    Label,
    DialogContent,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogActions,
    ToolbarToggleButton,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    MessageBarActions,
    MessageBarIntent,
    Table,
    TableHeader,
    TableRow,
    Textarea,
    TableSelectionCell,
    renderCombobox_unstable,
    TableBody,
    TableCell,
    useTableFeatures,
    useTableSelection,
    Spinner,
    DialogTrigger,
    ToolbarGroup,
    Checkbox,
    TableColumnId,
    Toolbar, ToolbarButton,
    Combobox, Option
} from "@fluentui/react-components";
import { IDirEntry, IEntryType, IQuestion, ISalesForce, ISalesForceFieldMapping, ISalesForceLinkMapping, IWebTemplateSimple, QuestionType } from "../ddentities";
import {
    AddRegular,
    CopyRegular,
    DeleteRegular,
    DismissRegular,
    DocumentTableRegular,
    ListRegular

} from "@fluentui/react-icons";
import { CustomFluentProvider } from "documentdrafter-components";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import { getCustomTheme } from "documentdrafter-components";
import { Breadcrumb, CheckboxVisibility, DefaultButton, DetailsList, IBreadcrumbItem, IColumn, Icon, IconButton, Link, Panel, PanelType, PrimaryButton, SelectionMode, TooltipDelay, TooltipHost } from "@fluentui/react";
import { classNames, newGuid } from "../basefunctions";
import { getTemplatePackage } from "../../apiFunctions/questionnairefunctions";
import { getAuthObject } from "../../apiFunctions/authenticateFunctions";

interface ISalesForceDocument {

    dataSet: ISalesForce;
    refresh: any;
    closeGrid: any;
    updateDataSet: (dataSet: ISalesForce) => void;
    directories: IDirEntry[],

}

export interface IDocsInScopeProcessed {
    id: string,
    name: string,
    type: string,
    location: string,
}


export const EditSalesForce: React.FC<ISalesForceDocument> = (props) => {

    const isDark = usePersistentStore().darkMode;
    const portalConfig = useStore().portalConfig;
    const theme = isDark ? webDarkTheme : webLightTheme;
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const [showMessageBar, setShowMessageBar] = useState<boolean>(false);
    const [messageBarText, setMessageBarText] = useState<string>("");
    const [messageBarType, setMessageBarType] = useState<MessageBarIntent>("info");
    const [showDocumentsPanel, setShowDocumentsPanel] = useState<boolean>(false);
    const [docsInScopeProcessed, setDocsInScopeProcessed] = useState<IDocsInScopeProcessed[]>([]);
    const [salesForceDoc, setSalesForceDoc] = useState<ISalesForce>(props.dataSet);
    const [templatePackage, setTemplatePackage] = useState<IWebTemplateSimple>(null);
    const [cleanedQuestions, setCleanedQuestions] = useState<IQuestion[]>([]);
    const useStyles = makeStyles({
        headerContainer:
        {
            display: 'flex',
            justifyContent: 'space-between',

        },
        headerColumn1: {
            marginRight: 'auto'
        },
        tableHeader:
        {
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: tokens.colorNeutralBackground1
        }
        ,
        toolbar: {
            justifyContent: "space-between",
        },


    })
    const styles = useStyles();

    function Header() {
        return (
            <div className={styles.headerContainer}
                style={{
                    marginLeft: tokens.spacingHorizontalXL,
                    marginRight: tokens.spacingHorizontalXL,
                    marginTop: tokens.spacingVerticalXXXL,
                    marginBottom: tokens.spacingVerticalXL,
                }}

            >
                <div

                    className={styles.headerColumn1}>


                    <h3>Configuration of table {props.dataSet.name}</h3>
                </div>


                <Toolbar


                >
                    <ToolbarButton

                        icon={<DismissRegular />} onClick={() => {

                            props.closeGrid(true);


                        }} />
                </Toolbar>
            </div>

        )
    }

    function ShowMessageDialog() {

        return (


            <MessageBar key={messageBarType} intent={messageBarType}   >
                <MessageBarBody>
                    <MessageBarTitle>{messageBarText}</MessageBarTitle>


                </MessageBarBody>
                <MessageBarActions
                    containerAction={
                        <Button
                            aria-label="dismiss"
                            appearance="transparent"
                            icon={<DismissRegular />}
                            onClick={() => setShowMessageBar(false)}
                        />
                    }
                >
                </MessageBarActions>
            </MessageBar>


        )
    }
    const MoveUpIcon = () => <Icon iconName="Up" className={[classNames.fileIconImg, classNames.colorThemePrimary].join(' ')} />;
    const LibraryIcon = () => <Icon style={{ fontSize: "16px" }} iconName="Library" className={[classNames.fileIconImg, classNames.colorThemePrimary].join(' ')} />;
    interface IDocumentPanel {
        directories: IDirEntry[],
        docsInScopeProcessed: IDocsInScopeProcessed[];
        salesForceDoc: ISalesForce;
        setSalesForceDoc: (flow: ISalesForce) => void;
        isOpen: boolean;
        setIsOpen: (open: boolean) => void;
    }

    const DocumentPanel: React.FC<IDocumentPanel> = (props) => {

        const [currentFolder, setCurrentFolder] = useState<string>('');
        const [addedDirEntries, setAddedDirEntries] = useState<IDirEntry[]>([]);
        const [addedFoldersHidden, setAddedFoldersHidden] = useState<boolean>(false);

        const breadcrumbItems: IBreadcrumbItem[] = [];
        let parentId = currentFolder;

        while (parentId) {
            const directory = props.directories.find(directory => directory.id === parentId);

            breadcrumbItems.push({
                text: directory.name,
                key: parentId,
                href: '#',
                onClick: () => setCurrentFolder(directory.id),
            });

            parentId = directory.parentid;
        }

        breadcrumbItems.push({
            text: "Workspaces",
            key: "",
            href: "#",
            onClick: () => setCurrentFolder(""),
        });

        breadcrumbItems.reverse();

        const _columnsRemove: IColumn[] = [
            {
                key: 'column1',
                name: 'File Type',
                className: classNames.fileIconCell,
                iconClassName: classNames.fileIconHeaderIcon,
                ariaLabel: 'Column operations for File type, Press to sort on File type',
                iconName: 'Page',
                isIconOnly: true,
                fieldName: 'name',
                minWidth: 18,
                maxWidth: 18,
                isPadded: false,
                onRender: (item: IDirEntry) => {
                    if (item.parentid == "") {
                        return <LibraryIcon />;
                    } else if (item.parentid == "x") {
                        return <MoveUpIcon />;
                    } else if (item.entrytype === 0) {
                        return <img src={"https://static2.sharepointonline.com/files/fabric/assets/item-types-fluent/20/folder.svg"} alt="folder" className={classNames.fileIconImg} />;
                    } else if (item.versions[item.versions.length - 1]?.files?.length > 2) {
                        return <Icon style={{ paddingTop: "4px", fontSize: "13px" }} iconName="Package" aria-hidden={true} />;
                    } else {
                        return <img src={"https://static2.sharepointonline.com/files/fabric/assets/item-types-fluent/20/docx.svg?v6"} className={classNames.fileIconImg} alt={item.name + ' file icon'} />;
                    }
                }
            },
            {
                key: 'column2',
                name: 'Name',
                fieldName: 'name',
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                className: classNames.selectHover,
                data: 'string',
                isPadded: true,
                onRender: (item: IDirEntry) => {
                    if (item.entrytype === 0) {
                        return <Link className={classNames.filemanagerDialogFont} onClick={() => setCurrentFolder(item.id)}>{item.name}</Link>;
                    } else {
                        return (
                            <TooltipHost content={item.name} delay={TooltipDelay.long}>
                                <span> {item.name} </span >
                            </TooltipHost>
                        );
                    }
                },
            },
            {
                key: 'column3',
                name: 'Name',
                fieldName: 'name',
                minWidth: 18,
                maxWidth: 18,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                className: classNames.selectHover,
                data: 'string',
                isPadded: true,
                onRender: (item: IDirEntry) =>
                    <IconButton
                        iconProps={{ iconName: "ChromeClose" }}
                        styles={{ icon: { color: '#212121' } }}
                        onClick={() => setAddedDirEntries(prevEntries => prevEntries.filter(entry => entry.id !== item.id))}
                        style={{ fontSize: 16, width: 16, height: 16 }}
                    />,
            },
        ];

        const _columnsAdd: IColumn[] = [
            {
                key: 'column1',
                name: 'File Type',
                className: classNames.fileIconCell,
                iconClassName: classNames.fileIconHeaderIcon,
                ariaLabel: 'Column operations for File type, Press to sort on File type',
                iconName: 'Page',
                isIconOnly: true,
                fieldName: 'name',
                minWidth: 18,
                maxWidth: 18,
                isPadded: false,
                onRender: (item: IDirEntry) => {
                    if (item.parentid == "") {
                        return <LibraryIcon />;
                    } else if (item.parentid == "x") {
                        return <MoveUpIcon />;
                    } else if (item.entrytype === 0) {
                        return <img src={"https://static2.sharepointonline.com/files/fabric/assets/item-types-fluent/20/folder.svg"} alt="folder" className={classNames.fileIconImg} />;
                    } else if (item.versions[item.versions.length - 1]?.files?.length > 2) {
                        return <Icon style={{ paddingTop: "4px", fontSize: "13px" }} iconName="Package" aria-hidden={true} />;
                    } else {
                        return <img src={"https://static2.sharepointonline.com/files/fabric/assets/item-types-fluent/20/docx.svg?v6"} className={classNames.fileIconImg} alt={item.name + ' file icon'} />;
                    }
                }
            },
            {
                key: 'column2',
                name: 'Name',
                fieldName: 'name',
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                className: classNames.selectHover,
                data: 'string',
                isPadded: true,
                onRender: (item: IDirEntry) => {
                    if (item.entrytype === 0) {
                        return <Link className={classNames.filemanagerDialogFont} onClick={() => setCurrentFolder(item.id)}>{item.name}</Link>;
                    } else {
                        return (
                            <TooltipHost content={item.name} delay={TooltipDelay.long}>
                                <span> {item.name} </span >
                            </TooltipHost>
                        );
                    }
                },
            },
            {
                key: 'column3',
                name: 'Name',
                fieldName: 'name',
                minWidth: 18,
                maxWidth: 18,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                className: classNames.selectHover,
                data: 'string',
                isPadded: true,
                onRender: (item: IDirEntry) => {
                    if (item.entrytype != IEntryType.Folder) {
                        return <IconButton
                            iconProps={{ iconName: "Add" }}
                            styles={{ icon: { color: '#212121' } }}
                            onClick={() => setAddedDirEntries(prevEntries => [...prevEntries, item])}
                            style={{ fontSize: 16, width: 16, height: 16 }}
                        />
                    }
                  
                }
                    
            },
        ];

        const renderPanelHeader = () => (
            <div style={{ width: "100%" }}>
                <div style={{ paddingLeft: 20, fontSize: 20, fontWeight: 600, lineHeight: "27px" }}>Select documents in scope</div>
            </div>
        );

        const renderPanelBody = () => {
            return (
                <div style={{ marginLeft: 8, height: "calc(100vh - 120px)", overflowY: "scroll" }}>
                    <div
                        style={{ width: "100%", marginTop: 32, marginBottom: addedDirEntries.length ? 12 : 32, fontSize: 14, lineHeight: '20px', fontWeight: 600 }}
                    >
                        <span style={{ display: "inline-block", width: 100, marginLeft: 12 }}>Adding ({addedDirEntries.length})</span>

                        {addedFoldersHidden || !addedDirEntries.length ?
                            <IconButton
                                iconProps={{ iconName: "ChevronDown" }}
                                onClick={() => setAddedFoldersHidden(false)}
                                styles={{ root: { position: "relative", top: 4, left: 206 }, icon: { height: 19, color: '#212121' } }} /> :
                            <IconButton
                                iconProps={{ iconName: "ChevronUp" }}
                                onClick={() => setAddedFoldersHidden(true)}
                                styles={{ root: { position: "relative", top: 4, left: 206 }, icon: { height: 19, color: '#212121' } }} />
                        }
                    </div>

                    {addedDirEntries.length ?
                        <DetailsList
                            items={addedDirEntries}
                            compact={true}
                            columns={_columnsRemove}
                            selectionMode={SelectionMode.none}
                            onRenderDetailsHeader={() => null}
                            checkboxVisibility={CheckboxVisibility.hidden}
                            styles={{ root: { display: addedFoldersHidden ? "none" : "block", marginBottom: 32 } }}
                        /> : null
                    }


                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <IconButton
                            styles={{
                                root: { height: 20 },
                                rootDisabled: { backgroundColor: "#FFF" }
                            }}
                            disabled={breadcrumbItems.length < 2}
                            iconProps={{ iconName: "Up" }}
                            onClick={() => { setCurrentFolder(breadcrumbItems[breadcrumbItems.length - 2].key); }}
                        />

                        <span style={{ width: 360 }}>
                            <Breadcrumb
                                items={breadcrumbItems}
                                maxDisplayedItems={breadcrumbItems.length}
                                styles={{ root: { margin: 0 } }}
                            />
                        </span>
                    </div>

                    <DetailsList
                        items={props.directories.filter(directory =>
                            directory.name &&
                            directory.parentid === currentFolder &&
                            !addedDirEntries.map(x => x.id).includes(directory.id) &&
                            !props.docsInScopeProcessed.map(x => x.id).includes(directory.id)
                        )}
                        compact={true}
                        columns={_columnsAdd}
                        selectionMode={SelectionMode.none}
                        // layoutMode={DetailsListLayoutMode.justified}
                        // constrainMode={ConstrainMode.unconstrained}
                        onRenderDetailsHeader={() => null}
                        checkboxVisibility={CheckboxVisibility.hidden}
                    />
                </div>
            );
        };

        const renderPanelFooter = () => {
            return <div style={{ position: "absolute", bottom: 0 }}>
                <PrimaryButton
                    styles={{ root: { margin: "24px 16px 16px 24px" } }}
                    disabled={!addedDirEntries.length}
                    onClick={() => {
                        var _salesForceDoc = structuredClone(props.salesForceDoc);

                        _salesForceDoc.templateId = addedDirEntries[0].id;
                        _salesForceDoc.templateName = addedDirEntries[0].name;
                        props.setSalesForceDoc(_salesForceDoc);
                        props.setIsOpen(false);
                        setAddedDirEntries([]);
                    }}
                >
                    Save
                </PrimaryButton>

                <DefaultButton
                    onClick={() => {
                        props.setIsOpen(false);
                        setAddedDirEntries([]);
                    }}
                >
                    Cancel
                </DefaultButton>
            </div>;
        };

        return (
            <Panel
                onRenderHeader={renderPanelHeader}
                onRenderBody={renderPanelBody}
                onRenderFooter={renderPanelFooter}
                isOpen={props.isOpen}
                onDismiss={() => {
                    props.setIsOpen(false);
                    setAddedDirEntries([]);
                }}
                closeButtonAriaLabel="Close"
                type={PanelType.custom}
                customWidth="400px"
            />
        )

    }

    async function updateTemplateSelection(updated: ISalesForce) {
        if (updated.templateId != props.dataSet.templateId) {
            var tmp = { ...salesForceDoc};
            tmp.templateId = updated.templateId;
            tmp.templateName = updated.templateName;
            await loadTemplatePackage(updated.templateId);
            props.updateDataSet(tmp);
            props.closeGrid(true);

                

        }

    }


    useEffect(()=> {
        //load ques
        console.log('refresh');

      
    }, [])

    async function updateQuestion(item: ISalesForceFieldMapping, value:string) {
        var tmp = { ...salesForceDoc };
        tmp.fields.find(x => x.id == item.id).q_Id = value;
        props.updateDataSet(tmp);
        setSalesForceDoc(tmp);
    }

    async function updateQuestionLink(item: ISalesForceFieldMapping, value: string) {
        var tmp = { ...salesForceDoc };
        tmp.fields.find(x => x.id == item.id).sF_Field = value;
        props.updateDataSet(tmp);
        setSalesForceDoc(tmp);
    }

    async function updateTableLink1(item: ISalesForceLinkMapping, value: string) {
        var tmp = { ...salesForceDoc };
        tmp.links.find(x => x.id == item.id).tableField = value;
        props.updateDataSet(tmp);
        setSalesForceDoc(tmp);
    }
    async function updateTableLink2(item: ISalesForceLinkMapping, value: string) {
        var tmp = { ...salesForceDoc };
        tmp.links.find(x => x.id == item.id).tableField2 = value;
        props.updateDataSet(tmp);
        setSalesForceDoc(tmp);
    }


    async function removeLine(item: ISalesForceFieldMapping) {
        var tmp = { ...salesForceDoc };
        tmp.fields = tmp.fields.filter(x => x.id !== item.id);
        props.updateDataSet(tmp);
        setSalesForceDoc(tmp);
    }
    function cleanAndTruncateHtml(input: string, maxLength: number = 100): string {
        // Remove all HTML tags using a regex
        const textOnly = input.replace(/<\/?[^>]+(>|$)/g, "");

        // Extract the first line by splitting on line breaks
        const firstLine = textOnly.split(/\r?\n/)[0] || "";

        // Truncate the line with dots if it exceeds maxLength
        return firstLine.length > maxLength ? firstLine.substring(0, maxLength) + "..." : firstLine;
    }

    async function removeTableLinkLine(item: ISalesForceLinkMapping) {
        var tmp = { ...salesForceDoc };
        tmp.links = tmp.links.filter(x => x.id !== item.id);
        props.updateDataSet(tmp);
        setSalesForceDoc(tmp);
    }

    async function loadTemplatePackage(templateId:string) {
        var authObject = await getAuthObject();
        var _package = await getTemplatePackage(authObject, templateId);

        var _newList: IQuestion[] = [];

        for (var i = 0; i < _package.package.Questions.length; i++) {

            var q = _package.package.Questions[i];

            if (q.Type === QuestionType.DATA_FIELD || q.Type === QuestionType.ALTERNATIVE_TEXT)
            {

                q.Text = cleanAndTruncateHtml(q.Text);

                _newList.push(q)
            }

        }
        setCleanedQuestions(_newList);
        setTemplatePackage(_package);
    }




    useEffect(() => {

        const init = async () => {
            await loadTemplatePackage(salesForceDoc.templateId);
        }




        if(salesForceDoc.templateId)
            init();

    },[])



    
    function getDefaultValue(id: string) {
        if (id) {
            return templatePackage.package.Questions.find(x => x.Id === id)?.Text;
        }
        else {
            return "";
        }
    }

   

    return (

        <CustomFluentProvider isDark={isDark} colorConfiguration={portalConfig?.colorConfiguration}>
            <div
                style={{
                    height: "100vh",
                    width: "100vw",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 9999,
                    background:
                        `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`

                }}
            >
                {showMessageBar && ShowMessageDialog()}
                {Header()}



                <div
                    style={{
                        overflowX: "hidden",

                        backgroundColor: tokens.colorNeutralBackground1,
                        color: tokens.colorNeutralForeground1,
                        borderTopLeftRadius: tokens.borderRadiusXLarge,
                        borderTopRightRadius: tokens.borderRadiusXLarge,
                        marginLeft: tokens.spacingHorizontalL,
                        marginRight: tokens.spacingHorizontalL,
                        height: "80dvh",
                        width: "97vw"


                    }}>


                    <Label style={{ marginLeft: 20, marginTop: 20,  fontWeight:'bold' }}>Template</Label>
                    <div style={{ display: "flex", alignItems: "center", marginLeft: 20, marginTop: 4 }}>
                        <Label
                            
                            style={{ width: 360, marginRight: 8 }}
                        > {salesForceDoc.templateName?salesForceDoc.templateName:"Select template"}</Label>
                        <IconButton
                            iconProps={{ iconName: "Edit" }}
                            onClick={() => setShowDocumentsPanel(true)}
                        />
                    </div>
                 
                  
                        <div>
                            <Label style={{ marginLeft: 20, marginTop: 20, fontWeight: 'bold' }}>Integration Id</Label>

                        <div style={{ display: "flex", alignItems: "center", marginLeft: 20, marginTop: 4 }}>
                            {salesForceDoc.id}
                            <Button
                                style={{ marginLeft :"5px"} }
                                size="small"
                                onClick={() => { navigator.clipboard.writeText(salesForceDoc.id) }}
                                icon={<CopyRegular

                                    aria-label="copy to clipboard"

                                    onClick={() => navigator.clipboard.writeText(salesForceDoc.id)}
                                ></CopyRegular>}
                                iconPosition={"after"}

                            >
                             
                            </Button>

                        </div></div>

                    {salesForceDoc.templateId &&
                        
                       <div style={{ display: "flex", alignItems: "center", marginLeft: 20, marginTop: 4, gap: "20px",  }}>
                            <div style={{ flex: 1, height:"55vh" }}>

                                <h3>Fields mapping</h3>

                                {templatePackage != null && <Table

                                    style={{ minWidth: "550px", paddingLeft: "8px", paddingRight: "8px" }}
                                >
                                    <TableHeader
                                        className={styles.tableHeader}

                                    >
                                        <TableRow>
                                            <TableHeaderCell style={{ width:"45%" } } >Question</TableHeaderCell>
                                            <TableHeaderCell style={{ width: "45%" }} >Salesforce field</TableHeaderCell>  
                                            <TableHeaderCell style={{ width: "10%" }} ></TableHeaderCell>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>

                                        {salesForceDoc.fields.map((item) => (
                                            <TableRow
                                                key={item.id}
                                                data-itemID={item.id}

                                            >
                                                <TableCell>
                                                    <TableCellLayout style={{ width: '45%' }} >
                                                        <Combobox
                                                            placeholder="Select question"
                                                            defaultValue= {getDefaultValue(item.q_Id)}
                                                            defaultSelectedOptions={item.q_Id?[item.q_Id]:[]}
                                                            clearable
                                                            
                                                            style={{ width: "250px" }}
                                                            onActiveOptionChange={async (_, data) => { await updateQuestion(item,data?.nextOption?.value) }}
                                                        >
                                                            {cleanedQuestions.map((option) => (
                                                                <Option key={option.Id} value={option.Id}   >
                                                                    {"Q" + option.DisplayNumber + " " + option.Text}
                                                                </Option>

                                                            ))}

                                                        </Combobox>
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell>
                                                    <TableCellLayout style={{ width: '45%' }} >
                                                        <Input
                                                            defaultValue={item.sF_Field}
                                                            appearance="outline"
                                                            data-id={item.id}
                                                            onBlur={async (e) => {

                                                                await updateQuestionLink(item, e.target.value);
                                                                //updateCellDataInput(e.currentTarget);
                                                                //setFocusedCell(null);

                                                            }}
                                                            style={{ width: "250px" }}
                                                            maxLength={200}
                                                            size="small"

                                                        ></Input>
                                                    </TableCellLayout>
                                                </TableCell >
                                                <TableCell>
                                                    <TableCellLayout style={{ width: '10%' }} >
                                                        <Button icon={<DeleteRegular />}
                                                            aria-label="Delete"
                                                            onClick={async () => { await removeLine(item); } }

                                                        />
                                                    </TableCellLayout>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>



                                
                                </Table>}

                              


                                <Toolbar aria-label="Vertical Button" className={useStyles().toolbar} >
                                    <ToolbarGroup>

                                        <ToolbarButton icon={<AddRegular />}
                                            onClick={async () => {
                                                var tmp = { ...salesForceDoc };

                                                tmp.fields.push({ q_Id: "", sF_Field: "", id: newGuid() });
                                                setSalesForceDoc(tmp);
                                                props.updateDataSet(tmp);
                                            }}



                                        >Add mapping
                                        </ToolbarButton>
                                    </ToolbarGroup>
                                </Toolbar>
                            </div>
                            

                            <div style={{ flex: 1, height: "55vh" }}>
                                <h3>Object linking</h3>

                                {templatePackage != null && <Table

                                    style={{ minWidth: "550px", paddingLeft: "8px", paddingRight: "8px", height: "55vvh" }}
                                >
                                    <TableHeader
                                        className={styles.tableHeader}

                                    >
                                        <TableRow>
                                            <TableHeaderCell style={{ width: "45%" }} >Object from</TableHeaderCell>
                                            <TableHeaderCell style={{ width: "45%" }} >Object to</TableHeaderCell>
                                            <TableHeaderCell style={{ width: "10%" }} ></TableHeaderCell>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>

                                        {salesForceDoc.links.map((item) => (
                                            <TableRow
                                                key={item.id}
                                                data-itemID={item.id}

                                            >
                                                <TableCell>
                                                    <TableCellLayout style={{ width: '45%' }} >
                                                        <Input
                                                            defaultValue={item.tableField}
                                                            appearance="outline"
                                                            data-id={item.id}
                                                            onBlur={async (e) => {

                                                                await updateTableLink1(item, e.target.value);
                                                                //updateCellDataInput(e.currentTarget);
                                                                //setFocusedCell(null);

                                                            }}
                                                            style={{ width: "250px" }}
                                                            maxLength={200}
                                                            size="small"

                                                        ></Input>
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell>
                                                    <TableCellLayout style={{ width: '45%' }} >
                                                        <Input
                                                            defaultValue={item.tableField2}
                                                            appearance="outline"
                                                            data-id={item.id}
                                                            onBlur={async (e) => {

                                                                await updateTableLink2(item, e.target.value);
                                                                //updateCellDataInput(e.currentTarget);
                                                                //setFocusedCell(null);

                                                            }}
                                                            style={{ width: "250px" }}
                                                            maxLength={200}
                                                            size="small"

                                                        ></Input>
                                                    </TableCellLayout>
                                                </TableCell >
                                                <TableCell>
                                                    <TableCellLayout style={{ width: '10%' }} >
                                                        <Button icon={<DeleteRegular />}
                                                            aria-label="Delete"
                                                            onClick={async () => { await removeTableLinkLine(item); }}

                                                        />
                                                    </TableCellLayout>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>


                                </Table>}
                                <Toolbar aria-label="Vertical Button" className={useStyles().toolbar} >
                                    <ToolbarGroup>

                                        <ToolbarButton icon={<AddRegular />}
                                            onClick={async () => {
                                                var tmp = { ...salesForceDoc };

                                                tmp.links.push({ tableField:"",tableField2:"" , id: newGuid() });
                                                setSalesForceDoc(tmp);
                                                //props.updateDataSet(tmp);
                                            }}



                                        >Add link
                                        </ToolbarButton>
                                    </ToolbarGroup>
                                </Toolbar>
                            </div>
                        </div>
                    }





                    <DocumentPanel
                        directories={props.directories}
                        docsInScopeProcessed={docsInScopeProcessed}
                        salesForceDoc={salesForceDoc}
                        setSalesForceDoc={updateTemplateSelection}
                        isOpen={showDocumentsPanel}
                        setIsOpen={setShowDocumentsPanel}
                    />


                    {/*<Toolbar aria-label="Vertical Button" className={useStyles().toolbar}  >*/}
                    {/*    <ToolbarGroup>*/}

                    {/*        */}{/*<ToolbarButton icon={activeDataSet.enabled ? <LockOpenRegular /> : <LockClosedRegular />} onClick={() => {*/}
                    {/*        */}{/*    var dataSet = activeDataSet;*/}
                    {/*        */}{/*    dataSet.enabled = !dataSet.enabled;*/}
                    {/*        */}{/*    UpdateEnabledOnDataSet(dataSet);*/}


                    {/*        */}{/*}}>{activeDataSet.enabled ? "Disable" : "Enable"}*/}
                    {/*        */}{/*</ToolbarButton>*/}

                    {/*        <ToolbarButton disabled={activeDataSet.enabled} icon={activeDataSet.endPointType === apiendpointtype.list ? <TableRegular /> : <BookSearchRegular />} onClick={() => {*/}
                    {/*            var dataSet = activeDataSet;*/}
                    {/*            if (dataSet.endPointType == apiendpointtype.list) {*/}
                    {/*                dataSet.endPointType = apiendpointtype.selector;*/}
                    {/*            }*/}
                    {/*            else {*/}
                    {/*                dataSet.endPointType = apiendpointtype.list;*/}
                    {/*            }*/}

                    {/*            UpdateEndPointTypeDataSet(dataSet);*/}

                    {/*        }}>Display model*/}
                    {/*        </ToolbarButton>*/}
                    {/*        <ToolbarButton*/}
                    {/*            icon={<CallConnectingRegular />}*/}
                    {/*            onClick={async () => {*/}
                    {/*                let authObj = await getAuthObject();*/}
                    {/*                let result = await RefreshTable(authObj, activeDataSet.id);*/}
                    {/*                setActiveDataset(result);*/}
                    {/*                props.updateDataSet(result);*/}
                    {/*            }}*/}
                    {/*        >*/}

                    {/*        </ToolbarButton>*/}
                    {/*    </ToolbarGroup >*/}


                    {/*</Toolbar>*/}
                    {/* {items.length} {hasMoreData?"HasMoreData":"NoMoreData"}*/}



                    
                     

                    {/*<DataGrid */}
                    {/*    items={activeDataSet.columns}*/}
                    {/*    columns={columns}*/}
                    {/*    style={{ minWidth: "550px", paddingLeft: "8px", paddingRight: "8px" }}*/}
                    {/*>*/}
                    {/*    <DataGridHeader>*/}
                    {/*        <DataGridRow>*/}
                    {/*            {({ renderHeaderCell }) => (*/}
                    {/*                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>*/}
                    {/*            )}*/}
                    {/*        </DataGridRow>*/}
                    {/*    </DataGridHeader>*/}

                    {/*    <DataGridBody<IDataSetColumn>>*/}
                    {/*        {({ item, rowId }) => (*/}
                    {/*            <DataGridRow<IDataSetColumn>*/}
                    {/*                key={rowId}*/}

                    {/*            >*/}
                    {/*                {({ renderCell, columnId }) => (*/}
                    {/*                    <DataGridCell>*/}
                    {/*                        {renderCellAction(columnId, item, rowId)}*/}
                    {/*                    </DataGridCell>*/}
                    {/*                )}*/}
                    {/*            </DataGridRow>*/}
                    {/*        )}*/}
                    {/*    </DataGridBody>*/}


                    {/*</DataGrid>*/}






                </div>
                <div
                    style={{
                        height: "33px",
                        width: "97vw",
                        backgroundColor: tokens.colorNeutralBackground1,
                        borderBottomRightRadius: tokens.borderRadiusXLarge,
                        borderBottomLeftRadius: tokens.borderRadiusXLarge,
                        marginLeft: tokens.spacingHorizontalL,
                        marginRight: tokens.spacingHorizontalL,
                    }}
                />

            </div>

        </CustomFluentProvider>

    )






}


