﻿import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    webLightTheme,
    webDarkTheme,
    themeToTokensObject,
    Button,
    DataGrid,
    tokens,
    TableColumnDefinition,
    createTableColumn,
    TableCellLayout,
    DataGridHeader,
    DataGridRow,
    DataGridHeaderCell,
    DataGridBody,
    DataGridCell,
   
    TableRowId,
    DataGridProps,

    Input,
    Label,
    DialogContent,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogActions,
    Title2,

    Spinner,
    Toast,
    ToastTitle,
    useToastController,
    ToastTrigger,
    useId,
    Link,
    ButtonProps
} from "@fluentui/react-components";
import { AddRegular, CallConnectingRegular, DeleteRegular, Dismiss24Regular, PersonAccountsRegular, DismissRegular, EditRegular, NavigationRegular, QuestionCircleRegular, RenameRegular, SignOutRegular, PlugConnectedFilled, PlugConnectedRegular, CopyRegular } from "@fluentui/react-icons";
import { Dropdown, Option, Toolbar, ToolbarButton } from "@fluentui/react-components";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import { Header, CustomMenuButton } from "documentdrafter-components";
import { ISlimWorkspace, ScreenSize, CustomFluentProvider } from "documentdrafter-components";
import { getCustomTheme } from "documentdrafter-components";
import { getEndpoints, setEndpoints, MainPageSignalRWrapper, ISignalRUpdate, SignalRUpdateType, ISlimCachedDirectory } from "documentdrafter-components";
import "@fluentui/react/dist/css/fabric.min.css";
import React from "react";
import { ICacheUserWithGroups, IUserProps, IUserShare } from "../entitiesAPI";
import { Selection, SelectionMode } from '@fluentui/react/lib/Selection';
import { v4 as uuidv4 } from 'uuid';


import {
    DocumentTableRegular,
    ListRegular

} from "@fluentui/react-icons";
import { ISalesForce, PortalGroupSlim, IDirEntry } from "../ddentities";
import { _copyAndSort, classNames, classNamesMessageBar, formatDate } from "../basefunctions";
import { getAuthObject } from "../../apiFunctions/authenticateFunctions";
import { DeleteDataSetSQLTable, AddSQLTableDataSet, GetSQlTables, GetAllSqlTableDataSets, GetDataBases, UpdateDataSetSQlTable, UpdateDataSetAccess, getShareUsers, UpdateAccessSalesForce, AddSalesForce, UpdateSalesForceName, GetAllSalesForceDocuments, GetSalesforceToken, DeleteSalesForceDocument, UpdateSalesForce, GetSalesForceDocument } from "../../apiFunctions/baseFunctions/baseFunctions";
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';
import { forEach } from "lodash";
import { LookUpTableGrid } from "./lookUpTableGrid";
import { AccessControl } from "../shared/AccessControl";
import { JSX } from "react/jsx-runtime";
import { DocumentPanel } from "../admin/flows/dialogs";
import { EditSalesForce } from "./salesForceEdit";
import { GetFolderStructureFull } from "../../apiFunctions/baseFunctions/directoryFunctions";

var _allUsers: IUserShare[] = [];


export const SalesForce: React.FC = () => {

    const userObj: IUserProps = useStore().userObj;
    const isUserAuthenticated = useStore().isUserAuthenticated;
    //SignalR
    const [documentsSignalRUpdateQueue, setDocumentsSignalRUpdateQueue] = useState<ISignalRUpdate[]>([]);
    const screenSize = useStore().screenSize;
    const portalConfig = useStore().portalConfig;


    const endPoints = useStore().endpoints;
    const isDark = usePersistentStore().darkMode;
    const theme = isDark ? webDarkTheme : webLightTheme;
    const navigate = useNavigate();


    const [dataSets, setDataSets] = React.useState<ISalesForce[]>([]);
    const [currentdataSet, SetCurrentDataSet] = React.useState<ISalesForce>(null);
    const [hideDialog, setHideDialog] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenDelete, setIsOpenDelete] = React.useState(false);
    const [isOpenRename, setIsOpenRename] = React.useState(false);
    const [isOpenEdit, setIsOpenEdit] = React.useState(false);
    const [isOpenToken, setIsOpenToken] = React.useState(false);
    const [isPopupVisible, setIsPopUpVisible] = React.useState(false);
    const [showMessageBar, setShowMessageBar] = React.useState<boolean>(false);
    const [NewName, setNewName] = React.useState<string>("");
    const [newEditor, setNewEditor] = React.useState<boolean>(false);
    const containerRef = React.useRef(null);
    const [items, setItems] = React.useState<Item[]>([]);
    const [open, setOpen] = React.useState(false);
    const [dataSetid, setDataSetid] = React.useState<string>("");
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const inputColumnRef = React.useRef<HTMLInputElement>(null);
    const [accessIsOpen, setAccessIsOpen] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
    const [errorMsg, setErrorMsg] = React.useState<string>("");
    const toasterId = useId("toaster43353453");
    const { dispatchToast } = useToastController(toasterId);
    const [isloading, setisloading] = React.useState(false);
    const [currentToken, setCurrentToken] = React.useState("");
    const [selectedValue, setSelectedValue] = useState<string>("");
    const [expirationDate, setExpirationDate] = useState<string>("");
    const [expirationAsDate, setExpirationAsDate] = useState<Date>(null);
   // const inputColumnRef = React.useRef<HTMLInputElement>(null);
    const [validityPeriod, setValidityPeriod] = useState<string>("");
    const [directory, setDirectory] = useState<IDirEntry[]>([]);


    var tmpConnectionString = "";


    type NameCell = {
        label: string;
        id: string;
    };

    type LastUpdatedCell = {
        label: string;
        timestamp: number;

    }
    type LastUpdateBy = {
        label: string;
    }
    type EnabledData = {
        label: string;
        enabled: boolean;
    }

    type Item = {
        name: NameCell;
        lastUpdated: LastUpdatedCell;
        lastUpdateBy: LastUpdateBy;
        enabledDataSet: EnabledData;
        templateName: string;
    };
    const notifyError = () =>
        dispatchToast(
            <Toast>
                <ToastTitle
                    action={
                        <ToastTrigger>
                            <Link>Dismiss</Link>
                        </ToastTrigger>

                    }


                >{errorMsg}</ToastTitle>
            </Toast>,
            { position: 'top', intent: "error" }
        );



    const handleSelectionChange = React.useCallback(
        (_, data) => {
        const value = data?.nextOption?.value;
        setSelectedValue(value);

        let date = new Date();
        switch (value) {
            case "No expire":
                date.setFullYear(date.getFullYear() + 99);
                setExpirationDate("No expiration");
                setExpirationAsDate(date);

                break;
            case "6 months":
                date.setMonth(date.getMonth() + 6);
                setExpirationDate(date.toDateString()); 
                setExpirationAsDate(date);                
                break;
            case "1 year":
                date.setFullYear(date.getFullYear() + 1);
                setExpirationDate(date.toDateString());
                setExpirationAsDate(date);
                break;
            case "2 years":
                date.setFullYear(date.getFullYear() + 2);
                setExpirationDate(date.toDateString());
                setExpirationAsDate(date);
                break;
            case "3 years":
                date.setFullYear(date.getFullYear() + 3);
                setExpirationDate(date.toDateString());
                setExpirationAsDate(date);
                break;
            case "4 years":
                date.setFullYear(date.getFullYear() + 4);
                setExpirationDate(date.toDateString());
                setExpirationAsDate(date);
                break;
            case "5 years":
                date.setFullYear(date.getFullYear() + 5);
                setExpirationDate(date.toDateString());
                setExpirationAsDate(date);
                break;
            case "10 years":
                date.setFullYear(date.getFullYear() + 10);
                setExpirationDate(date.toDateString());
                setExpirationAsDate(date);
                break;
            default:
                setExpirationDate("");
                break;
        }
        },[expirationDate]

    );


    const columns: TableColumnDefinition<Item>[] = [
        createTableColumn<Item>({
            columnId: "name",

            compare: (a, b) => {
                return a.name.label.localeCompare(b.name.label);
            },
            renderHeaderCell: () => {
                return "Name";



            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <div style={{ width: "500px" }}>{item.name.label}</div>
                         
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Item>({
            columnId: "lastUpdated",
            compare: (a, b) => {
                return a.lastUpdated.timestamp - b.lastUpdated.timestamp;
            },
            renderHeaderCell: () => {
                return "Last updated";
            },

            renderCell: (item) => {
                return (
                    <TableCellLayout style={{ width: "200px" }} >
                        {item.lastUpdated.label}
                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<Item>({
            columnId: "lastUpdateBy",
            compare: (a, b) => {
                return a.lastUpdateBy.label.localeCompare(b.lastUpdateBy.label);
            },
            renderHeaderCell: () => {
                return "Modified by";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.lastUpdateBy.label}
                    </TableCellLayout>
                );
            },
        }),

        createTableColumn<Item>({
            columnId: "templateName",
            //compare: (a, b) => {
            //    return a.enabledDataSet.enabled. localeCompare(b.enabledDataSet.enabled);
            //},
            renderHeaderCell: () => {
                return "Template Name";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.templateName?item.templateName : "n/a"}
                    </TableCellLayout>
                );
            },
        }),
        //createTableColumn<Item>({
        //    columnId: "enabledDataSet",
        //    //compare: (a, b) => {
        //    //    return a.enabledDataSet.enabled. localeCompare(b.enabledDataSet.enabled);
        //    //},
        //    renderHeaderCell: () => {
        //        return "Enabled";
        //    },
        //    renderCell: (item) => {
        //        return (
        //            <TableCellLayout >
        //                {item.enabledDataSet.enabled ? "Yes" : "No"}
        //            </TableCellLayout>
        //        );
        //    },
        //}),

    ]

    
    React.useEffect(() => {
        if (isOpen && inputColumnRef.current) {
            inputColumnRef.current.focus();
        }
    }, [isOpen]);

    React.useEffect(() => {
        if (isOpenRename && inputColumnRef.current) {
            inputColumnRef.current.focus();
            /* inputColumnRef.current.select();*/
        }
    }, [isOpenRename]);


    React.useEffect(() => { }, [dataSets]);


    //React.useEffect(() => {


    //    const run = async () => {

    //        if (isloading) {
    //            //dummy to avoid multiple calls
    //            setisloading(false);
    //            return;
    //        }

    //        if (connectionString.length === 0)
    //            return;

    //        setShowSpinner(true);
    //        let authObj = await getAuthObject();
    //        let result = await GetDataBases(authObj, connectionString);

    //        if (result.status === "OK" && !currentdataSet) {
    //            setFetchDataBases(result.dataBases.map(database => database.name));

    //        }
    //        else if (result.status === "OK" && currentdataSet) {
    //            var dbs = await GetDataBases(authObj, connectionString);
    //            var dbFound = dbs.dataBases.find(x => x.name === currentdataSet.database.name);
    //            if (!dbFound) {
    //                setErrorMsg("Database not found on connection");
    //                return;
    //            }
    //            var _tables = await GetSQlTables(authObj, connectionString, currentdataSet.database.name);
    //            var tableFound = _tables.tables.find(x => x.name === currentdataSet.tableInfo.name);
    //            if (!tableFound) {
    //                setErrorMsg("Table not found on connection");
    //                return;
    //            }
    //            setShowSpinner(false);
    //            setConnectionVerified(true);
    //            return;
    //        }
    //        else {
    //            setErrorMsg(result.status);
    //            notifyError();
    //        }
    //        setShowSpinner(false);
    //    }
    //    run();

    //}, [connectionString]);


    //React.useEffect(() => {
    //    const run = async () => {
    //        setShowSpinner(true);
    //        let authObj = await getAuthObject();
    //        let result = await GetSQlTables(authObj, connectionString, database);

    //        if (result.status === "OK") {

    //            setFetchTables(result.tables);
    //        }
    //        else {
    //            setErrorMsg(result.status);
    //            notifyError();
    //        }
    //        setShowSpinner(false);
    //        if (!currentdataSet)
    //            setConnectionVerified(true);
    //    }
    //    if (database.length == 0)
    //        return;

    //    run();

    //}, [database]);


    async function getAllDirectory() {

        var authResult = await getAuthObject();
        var dirs = await GetFolderStructureFull(authResult);
        setDirectory(dirs.directory);
        
    }

    function refreshSalesForce(force: boolean) {

        if (isOpenEdit && !force)
            return;

        getAuthObject().then((authResult) => {
            GetAllSalesForceDocuments(authResult).then((datasets) => {

                var tmpitems: Item[] = [];

                for (var i = 0; i < datasets.length; i++) {

                    var userEmail = "";
                    var formattedDate = "";

                    if (datasets[i].updated != undefined) {
                        let user = _allUsers.find(x => x.id === datasets[i].dataUpdatedBy)
                        userEmail = (user === undefined ? "n/a" : user.email);
                    }

                    try {
                        formattedDate = new Date(datasets[i].dataUpdated).toLocaleString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'

                        });

                    } catch (e) {
                        formattedDate = e.toString();

                    }

                    var tmp: Item = {
                        name: { label: datasets[i].name, id: datasets[i].id },
                        enabledDataSet: { label: datasets[i].enabled ? "Yes" : "No", enabled: datasets[i].enabled },
                        lastUpdateBy: { label: userEmail },
                        lastUpdated: { label: formattedDate, timestamp: new Date(datasets[i].dataUpdated).getTime() },
                        templateName : datasets[i].templateName?datasets[i].templateName:"n/a"
                    }

                    tmpitems.push(tmp);
                }
                setItems(tmpitems);
                setDataSets(datasets);
            });

        });
    }
    const refreshusers = () => {

        return new Promise(resolve => {

            if (window.MyshareUsers != undefined) {

                var portalusers = window.MyshareUsers;
                var currentUser = JSON.parse(sessionStorage.getItem("user"));

                if (currentUser !== null && currentUser !== undefined) {
                    var u: IUserShare = {
                        email: currentUser.portalUser.Description.email,
                        id: currentUser.portalUser.id,
                        name: currentUser.portalUser.Description.name
                    };
                    portalusers.push(u);
                }
                _allUsers = portalusers;
                resolve("Ok");

            }
            else {

                getAuthObject().then((authResult) => {
                    getShareUsers(authResult).then((usersResult) => {

                        let pl: IUserShare[] = [];
                        pl = usersResult;
                        //_allUsers = pl;
                        window.MyshareUsers = pl;

                        var portalusers = pl;
                        var currentUser = JSON.parse(sessionStorage.getItem("user"));

                        if (currentUser !== null && currentUser !== undefined) {
                            var u: IUserShare = {
                                email: currentUser.portalUser.Description.email,
                                id: currentUser.portalUser.id,
                                name: currentUser.portalUser.Description.name
                            };
                            portalusers.push(u);
                        }
                        _allUsers = portalusers;
                        resolve("Ok");
                    });

                });
            }
        })
    }

    React.useEffect(() => {



        refreshusers().then(() => {
            

            refreshSalesForce(false);

        });

        getAllDirectory();







    }, []);

    const DeleteSelected = () => {
        return getAuthObject().then((authResult) => {
            var dataSetIdArray = Array.from(selectedRows);
            var deletePromises = dataSetIdArray.map(dataSetId => DeleteSalesForceDocument(authResult, dataSetId.toString()));
            return Promise.all(deletePromises);
        });
    }


    const AddNew = (name: string) => {
        getAuthObject().then((authResult) => {
            AddSalesForce(authResult, name).then((salesForceData) => {
                refreshSalesForce(false);
                SetCurrentDataSet(salesForceData);

                setIsOpen(false);
                setIsPopUpVisible(true);
                //SetAddColumnOpen(true);
            });


        });
    }

    const getToken = async (expireDate: Date) => {
        var authObject = await getAuthObject();
        var _token = await GetSalesforceToken(authObject, expireDate);
        setCurrentToken(_token.token);
        navigator.clipboard.writeText(_token.token);
        

    }

    

    //const updateDataSetName = (dataSetId: string, newName: string) => {
    //    const updatedDataSets = dataSets.map((dataSet) => {
    //        if (dataSet.id === dataSetId) {
    //            return { ...dataSet, name: newName };
    //        }
    //        return dataSet;
    //    });
    //    setDataSets(updatedDataSets);
    //};

    const SaveSalesForceData = async (salesForceData: ISalesForce) => {
        var authObj = await getAuthObject();
        var result = await UpdateSalesForce(authObj, salesForceData);
        SetCurrentDataSet(salesForceData);
        refreshSalesForce(true);
    }


    const UpdateSalesForceData = async (salesForceData: ISalesForce) => {
        var authObj = await getAuthObject();
        var result = await UpdateSalesForceName(authObj, salesForceData);
        setIsOpenRename(false);
        refreshSalesForce(true);
    }




    const [selectedRows, setSelectedRows] = React.useState(
        new Set<TableRowId>([])
    );
    const onSelectionChange: DataGridProps["onSelectionChange"] = (e, data) => {
        setSelectedRows(data.selectedItems);
    };

    function closeGrid(closeit: boolean) {
        if (closeit)
            setIsOpenEdit(false);
    }

    function forceRefresh() {
        refreshSalesForce(true);
    }


    function updateAccess(usersToAdd: ICacheUserWithGroups[], usersToRemove: ICacheUserWithGroups[], groupsToAdd: PortalGroupSlim[], groupsToRemove: PortalGroupSlim[]) {

        async function _update() {

            var authObj = await getAuthObject();
            var salesForce = await UpdateAccessSalesForce(authObj, currentdataSet.id, usersToAdd.map(user => user.id), usersToRemove.map(user => user.id), groupsToAdd.map(grp => grp.id), groupsToRemove.map(grp => grp.id))
            let updatedDataSets = dataSets.map(ds => ds.id === salesForce.id ? salesForce : ds);
            setDataSets(updatedDataSets);
            setAccessIsOpen(false);
        }

        _update();

    }

    function closeAccess() {
        setAccessIsOpen(false);
    }

    

    function disableOk() {

        if (currentdataSet) {
            if (NewName !== currentdataSet.name)
                return false;
            return true;
        }

        if (NewName.length === 0)
            return true;
       
        return false;

    }



   

    return (
        <div>
            <div
                style={{
                    marginTop: tokens.spacingVerticalNone,
                    marginBottom: tokens.spacingVerticalXL,
                    marginLeft: "20px",
                    maxWidth: "100%"

                }}
            >
                <Title2>Salesforce Documents</Title2>
            </div>


            {/*{isOpenEdit && <div>  <SqlTablesManage*/}
            {/*    closeGrid={closeGrid}*/}
            {/*    dataSet={currentdataSet}*/}
            {/*    refresh={forceRefresh}*/}
            {/*    updateDataSet={(dataSet: IDataSet) => {*/}

            {/*        const dataSetIndex = dataSets.findIndex(x => x.id === dataSet.id);*/}
            {/*        if (dataSetIndex > -1) {*/}
            {/*            const tmpDataSets = structuredClone(dataSets);*/}
            {/*            tmpDataSets[dataSetIndex] = dataSet;*/}

            {/*            setDataSets(tmpDataSets);*/}
            {/*            SetCurrentDataSet(dataSet);*/}
            {/*        }*/}
            {/*    }}*/}
            {/*></SqlTablesManage></div>}*/}


            {!open &&

                <div
                    style={{

                        backgroundColor: tokens.colorNeutralBackground1,
                        color: tokens.colorNeutralForeground1,
                        borderTopLeftRadius: tokens.borderRadiusXLarge,
                        borderTopRightRadius: tokens.borderRadiusXLarge,
                        marginLeft: tokens.spacingHorizontalM,
                        marginRight: tokens.spacingHorizontalM,

                        width: "80dvw"


                    }}>
                    <Toolbar aria-label="Vertical Button" >
                        <ToolbarButton icon={<AddRegular></AddRegular>} onClick={() => {
                            setNewName("");
                            tmpConnectionString = "";
                            setIsOpen(true);
                            SetCurrentDataSet(null);

                        }}>New

                        </ToolbarButton>
                        <ToolbarButton icon={<EditRegular></EditRegular>} disabled={selectedRows.size !== 1} onClick={async () => {

                            var dataSetIdArray = Array.from(selectedRows);
                            var dataSetId = dataSetIdArray[0];

                            var authObject = await getAuthObject();
                            var rec = await GetSalesForceDocument(authObject, dataSetId as string);
                            SetCurrentDataSet(rec);
                            setIsOpenEdit(true);

                        }}>Configure mapping
                        </ToolbarButton>
                        <ToolbarButton icon={<RenameRegular></RenameRegular>} disabled={selectedRows.size !== 1} onClick={() => {
                            setisloading(true);
                            var dataSetIdArray = Array.from(selectedRows);
                            var dataSetId = dataSetIdArray[0];
                            var dataset = dataSets.find(x => x.id === dataSetId.toString());
                            SetCurrentDataSet(dataset);
                            setNewName(dataset.name);

                            //setDatabase(dataset.database.name);
                            //setTable(dataset.tableInfo.name);
                            //setSchema(dataset.tableInfo.schema);
                            //setConnectionString(dataset.connectionString);
                            setIsOpen(true);


                        }} >Rename
                        </ToolbarButton>


                        <ToolbarButton icon={<DeleteRegular></DeleteRegular>} disabled={selectedRows.size === 0} onClick={() => {
                            setIsOpenDelete(true);

                        }}>
                            Delete
                        </ToolbarButton>

                        {userObj.permissions.findIndex(x => x == "Access management for data manager") > -1 &&
                            <ToolbarButton icon={<PersonAccountsRegular />} disabled={selectedRows.size !== 1} onClick={() => {

                                var dataSetIdArray = Array.from(selectedRows);
                                var dataSetId = dataSetIdArray[0];
                                var dataset = dataSets.find(x => x.id === dataSetId.toString());
                                SetCurrentDataSet(dataset);
                                setAccessIsOpen(true);

                            }}>
                                Access
                            </ToolbarButton>}

                        {userObj.permissions.findIndex(x => x == "Access management for data manager") > -1 &&
                            <ToolbarButton  icon={<PlugConnectedRegular />} onClick={() => {

                                //var dataSetIdArray = Array.from(selectedRows);
                                //var dataSetId = dataSetIdArray[0];
                                //var dataset = dataSets.find(x => x.id === dataSetId.toString());
                                //SetCurrentDataSet(dataset);
                                //setAccessIsOpen(true);
                                setIsOpenToken(true);

                            }}>
                                Get token
                            </ToolbarButton>}


                    </Toolbar></div>}
            {!open &&
                <div
                    style={{
                        overflowX: "hidden",

                        backgroundColor: tokens.colorNeutralBackground1,
                        color: tokens.colorNeutralForeground1,

                        marginLeft: tokens.spacingHorizontalM,
                        marginRight: tokens.spacingHorizontalM,

                        height: "69dvh",
                        width: "80dvw"


                    }}>


                    <DataGrid
                        items={items}
                        columns={columns}
                        sortable
                        selectionMode="multiselect"

                        getRowId={(item) => item.name.id}
                        focusMode="composite"
                        size="medium"
                        selectedItems={selectedRows}
                        onSelectionChange={onSelectionChange}
                        style={{ overflow: "auto" }}

                    >
                        <DataGridHeader>
                            <DataGridRow
                                selectionCell={{
                                    checkboxIndicator: { "aria-label": "Select all rows" },
                                }}
                            >
                                {({ renderHeaderCell }) => (
                                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                )}




                            </DataGridRow>

                        </DataGridHeader>
                        <DataGridBody<Item>>
                            {({ item, rowId }) => (
                                <DataGridRow<Item>
                                    key={rowId}
                                    selectionCell={{
                                        checkboxIndicator: { "aria-label": "Select row" },
                                    }}
                                >
                                    {({ renderCell }) => (
                                        <DataGridCell>{renderCell(item)}</DataGridCell>
                                    )}
                                </DataGridRow>
                            )}
                        </DataGridBody>
                    </DataGrid></div>
            }


            <Dialog open={isOpen}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setErrorMsg("");
                                        setIsOpen(false);
                                    }}
                                />
                            }
                        >
                            {currentdataSet ? "Edit name" : "Add"}
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Label >
                                    Name
                                </Label>
                                <Input autoFocus={isOpen} defaultValue={NewName} onChange={(e, v) => { setNewName(e.currentTarget.value); }} ref={inputColumnRef} />
                            </div>
                         
                            {showSpinner && <Spinner size="extra-tiny" label="Fetching data..." />}
                            {errorMsg.length > 0 && <div style={{ color: "red" }}>{errorMsg}</div>}


                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                setErrorMsg("");
                                setIsOpen(false);

                            }} >Close</Button>


                            <Button appearance="primary" disabled={disableOk()}
                                onClick={async () => {
                                    if (!currentdataSet) {
                                        SetCurrentDataSet(null);
                                        AddNew(NewName);
                                        setNewName("");
                                        setIsOpen(false);
                                    }
                                    else {
                                       
                                        currentdataSet.name = NewName;
                                        await UpdateSalesForceData(currentdataSet);
                                        setIsOpen(false);
                                    }

                                }}
                            >
                                Ok
                            </Button>


                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <Dialog open={isOpenToken}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setIsOpenToken(false);
                                    }}
                                />
                            }
                        >
                            Request token for Salesforce
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column", height:"120px" }}>
                              
                                <Label >
                                    Validity period
                                </Label>
                                <div
                                    style={{paddingBottom:"10px"} }
                                >
                                    <Dropdown
                                        onActiveOptionChange={handleSelectionChange}
                                        style={{width:"70%"} }

                                    >
                                    <Option value="No expire">No expire</Option>
                                    <Option value="6 months">6 months</Option>
                                    <Option value="1 year">1 year</Option>
                                    <Option value="2 years">2 years</Option>
                                    <Option value="3 years">3 years</Option>
                                    <Option value="4 years">4 years</Option>
                                    <Option value="5 years">5 years</Option>
                                    <Option value="10 years">10 years</Option>
                                    </Dropdown>
                                    <span style={{paddingLeft:"20px"} } ></span>
                                <Button
                                    appearance="primary"
                                    disabled={expirationDate.length === 0}
                                    style={{ width: "30px" } }
                                      
                                    onClick={async () => {
                                        await getToken(expirationAsDate);
                                    }}
                                    >
                                
                                    Get
                                    </Button>
                                </div>
                                {currentToken && <div>

                                    Token has been copied to the clipboard, this has to be set in the network header in Salesforce.
                                    

                                    <Button
                                        size="small"
                                        onClick={() => { navigator.clipboard.writeText(currentToken) }}
                                        icon={<CopyRegular
                                        
                                        aria-label="copy to clipboard"
                                       
                                        onClick={() => navigator.clipboard.writeText(currentToken)}
                                    ></CopyRegular>}
                                        iconPosition={"after"}

                                    >
                                     Copy again
                                    </Button>


                                

                                </div>}

                              

                               
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                setIsOpenToken(false);

                            }} >Close</Button>



                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>

            <Dialog open={isOpenRename}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setIsOpenRename(false);
                                    }}
                                />
                            }
                        >
                            Rename dataset
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Label >
                                    Name
                                </Label>
                                <Input defaultValue={NewName} autoFocus={isOpenRename} onChange={(e, v) => { setNewName(e.currentTarget.value); }} ref={inputColumnRef} />
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                setIsOpenRename(false);

                            }} >Close</Button>



                            <Button appearance="primary" disabled={NewName.length === 0}
                                onClick={() => {
                                    currentdataSet.name = NewName;
                                    UpdateSalesForceData(currentdataSet);

                                }}
                            >
                                Ok
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <Dialog open={isOpenDelete}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setIsOpenDelete(false);
                                    }}
                                />
                            }
                        >
                            Delete?
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column" }}>

                                Please confirm to delete {selectedRows.size} {selectedRows.size === 1 ? "Salesforce document" : "Salesforce documents"}, when deleted the documents can not be restored.
                            </div>

                        </DialogContent>
                        <DialogActions>


                            <Button onClick={() => {
                                setIsOpenDelete(false);

                            }} >Close</Button>
                            <Button
                                appearance="primary"
                                onClick={() => {
                                    DeleteSelected().then(() => {
                                        refreshSalesForce(false);
                                        setIsOpenDelete(false);
                                        setSelectedRows(new Set<TableRowId>([]));

                                    })

                                }}
                            >
                                Delete {selectedRows.size} {selectedRows.size === 1 ? "dataset" : "datasets"}
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>
            {accessIsOpen && <AccessControl close={closeAccess} users={currentdataSet.usersWithAccess} groups={currentdataSet.groupsWithAccess} update={updateAccess}  ></AccessControl>}
            {isOpenEdit && <EditSalesForce dataSet={currentdataSet} closeGrid={() => setIsOpenEdit(false)} refresh={refreshSalesForce} directories={directory} updateDataSet={SaveSalesForceData}   ></EditSalesForce>  }
            






            <div
                style={{
                    height: "33px",
                    width: "80dvw",
                    backgroundColor: tokens.colorNeutralBackground1,
                    borderBottomRightRadius: tokens.borderRadiusXLarge,
                    borderBottomLeftRadius: tokens.borderRadiusXLarge,
                    marginLeft: tokens.spacingHorizontalM,
                }}
            />
        </div>


    )


}



function dispatchToast(arg0: JSX.Element, arg1: { position: string; intent: string; }) {
    throw new Error("Function not implemented.");
}
